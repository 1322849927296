  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import {useNavigate} from "react-router-dom"
  import * as axiosClient from '../../../js/Axios';
  import * as constants from '../../../js/constants'
  import '../../../css/main.css'
  import {over} from 'stompjs';
  
  import { io } from 'socket.io-client';
  const voice = require('@twilio/voice-sdk')

  function ChatMessageAttachment(props){
      const chatBodyRef = useRef(null)
      const inputTextRef = useRef(null)
      const clientRef = useRef(null)
      var socket = null
      // const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3002';
      // const socket = io(URL);
      //     console.log("Inside "+localStorage.getItem('chat_uuid'))
      //     socket.on('connect', onConnect);
      //     socket.on('disconnect', onDisconnect);
      //     socket.on('message', onMessage);
      function onMessage(message){
          message = JSON.parse(message)
          console.log("Message on user side received "+message.type)
          if(message.type === 'welcome'){
       
          }else if(message.type === 'connect_to_user'){
             console.log(message)
          }
      }
      function onConnect(){ 
        var message = {"type": "user_connect", "user_id" : 1}
        socket.send(message)
      }

      function onDisconnect(){console.log("onDisconnect")}

      function onClickEvent(){
          var token = ((localStorage.getItem("chat_token") == null) ? "" : localStorage.getItem("chat_token"))
          var conversationId = ((localStorage.getItem("conversation_id") == null) ? 0 : localStorage.getItem("conversation_id"))
          var message = inputTextRef.current.value 
          socket.send({"type": "chat","content": message, "chat_token": token, 'conversation_id': conversationId});
      }
              

      function onChangeEvent(event){
          console.log(event.which)
          if(event.which === 13 )
              onClickEvent()
      }

      // useEffect(() => {
      //     console.log("useEffect chat ")
      // axiosClient.postRequest(constants.CHAT_URL+'generate_token',payload)
      //     .then(function (response) {
      //         console.log(response)
      //     }).catch(function (error) {
      //         console.log(error)
      // });
      // },[])//use effect


      return(
              <div class="chat-header-row">
                 <div class="chat-header-img">
                   <img src={require('../../../img/Headshot.jpg')} alt=''/>
                 </div>
                 <div class="chat-header-info">
                   <div class="main-header">
                     <div class="uplode-file">
                       <a href="sample.pdf" download>
                       <div class="uplode-file-item">
                         <div class="manu-icon">...</div>
                          <img src={require('../../../img/pdf.png')} alt=''/>
                          <div class="uplode-file-footer">
                          <p>Lorem Ipsum is simply dummy text </p>
                          <span>PDF</span>
                         </div>
                       </div>
                       </a>
                     </div>
                   </div>
                 </div>
              </div>      
          )

  }

  export default ChatMessageAttachment;