import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SubCategoryLine from './SubCategoryLine'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'



function CategoryLine(props){
	const subCategoryRef = useRef(null)
	const menuRef = useRef(null)
	const [subCategories, setSubCategories] = useState(null)	
	const showCreateSubCategoryRef = useRef(null)
	const nameRef = useRef(null)
	const descriptionRef = useRef(null)
	const indexRef = useRef(null)
	const [edit, setEdit] = useState(null)

	useEffect(()=>{
		menuRef.current.style.display = 'none'
		let subCategoriesList = props.element.subCategories.map((element, index) => (					
					<SubCategoryLine name={element.name} element={element} openSubCategory={openSubCategory} displayArticles={props.displayArticles}/>
				));
		setSubCategories(subCategoriesList)
	},[])

	function displaySubCategory(){
		if(subCategoryRef.current.style.display === 'none')
			subCategoryRef.current.style.display='block'
		else
			subCategoryRef.current.style.display='none'
	}
	function displayMenu(){
		menuRef.current.style.display = 'block'
	}	
	function hideMenu(e){
		menuRef.current.style.display = 'none'
	}
	function closeCreateSubCategoryForm(){				
		showCreateSubCategoryRef.current.style.display='none'
		setEdit(false)
	}
	function setShowCreateSubCategory(){
		showCreateSubCategoryRef.current.style.display='block'
	}
	function createSubCategory(event){
		event.preventDefault()
		let payload = {name: event.target.name.value, description: event.target.description.value}
		axiosClient.postRequest(constants.KB_URL+'category/'+props.element.id+'/sub_category', payload)
		.then(function (response) {
			setSubCategories([...subCategories, <SubCategoryLine name={response.data.name} element={response.data} openSubCategory={openSubCategory} displayArticles={props.displayArticles}/>])
			nameRef.current.value=''
			descriptionRef.current.value=''
			indexRef.current.value=''
			showCreateSubCategoryRef.current.style.display='none'
		}).catch(function (response) {
		    console.log(response);
		});
		setEdit(false)		
	}
	function openCategory(){		
		props.openCategory(props.name, props.element.description, props.element.id)
	}

	function openSubCategory(name, description, index){
		nameRef.current.value = name
		descriptionRef.current.value = description	
		indexRef.current.value = index
		setEdit(true)
		showCreateSubCategoryRef.current.style.display = 'block';
	}
	return (
	<>
		<div style={{width:'100%',marginTop:'15px', position:'relative',cursor:'pointer',fontSize:'16px',fontWeight:'500' }} onMouseOver={displayMenu} onMouseLeave={hideMenu}>
			<div style={{color:'rgb(60, 103, 170)',fontWeight:'800', width:'100%', display:'inline-block',backgroundColor:'transparent',fontSize:'16px'}} class='add-ellipsis' onClick={displaySubCategory} >{props.name}</div>
			<div style={{position:'absolute',right:'0px', display:'inline-block', width:'50px', height:'50px'}} >
				 	<Dropdown drop='down'  style={{verticalAlign:'center', zIndex:'100',position:'absolute',right:'0px'}} ref={menuRef} >
				      <Dropdown.Toggle style={{backgroundColor:'white', border:'white',paddingTop:'0px'}}>
				       <img src={require('../../../img/dots.png')} alt='' style={{ height: "20px" }} />
				      </Dropdown.Toggle>

				      <Dropdown.Menu  style={{verticalAlign:'center', zIndex:'100 !important'}}>
				      	<Dropdown.Item onClick={openCategory} style={{fontSize:'14px'}}>Edit</Dropdown.Item>
				        <Dropdown.Item onClick={()=>setShowCreateSubCategory()}  style={{fontSize:'14px'}}>Sub Category</Dropdown.Item>
				      </Dropdown.Menu>
				    </Dropdown>	
			</div>
		</div>
				<div style={{display:'none',paddingLeft:'5px',fontSize:'14px'}} ref={subCategoryRef}>
						{subCategories}
				</div>
		
		<div  class="kb-modal"  ref={showCreateSubCategoryRef} style={{display: 'none'}}>
	        <div class="kb-modal-content">
	            <span  onClick={(event)=>closeCreateSubCategoryForm()} style={{position:'absolute', right:'30px', cursor:'pointer',fontSize:'20px',zIndex:'100'}}>&times;</span>
	            <h4 style={{opacity:'0.8'}}>{ edit ? 'Update Sub Category' : 'Create Sub Category' }</h4>
	            <form onSubmit={createSubCategory}>
	        		<div class='create-form-label' style={{ marginTop: "3%"}}>Name:<p class='red-color'>*</p></div>
		              <div><input type="text" name="name" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Name'} required  maxlength='500' ref={nameRef}/></div>
		              <div class='create-form-label' style={{ marginTop: "3%"}}>Description></div>
		              <div><textarea  rows='5' name="description" style={{width: "90%"}} placeholder={'Description'}  ref={descriptionRef}/></div>
		              <input style={{display: "none"}} name='index' ref={indexRef}/>
		              <button class= 'ss-button'> { edit ? 'Update Sub Category' : 'Create Sub Category' }</button>
			    </form>
	        </div>
    	</div>  
    </>
	)
}

export default CategoryLine;