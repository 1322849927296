import React, { useState, useRef, useEffect, useContext } from "react";
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import '../../../css/main.css'
import {over} from 'stompjs'; 
import { io } from 'socket.io-client';
import ChatUserMessage from './ChatUserMessage'
import ChatCustomerMessage from './ChatCustomerMessage'
import ChatMessageAttachment from './ChatMessageAttachment'
import Attachment from '../../Ticket/js/Attachment'
import {ToastContainer, toast } from 'react-toastify';
import SocketContext from '../../SocketContext'
import ProfilePicture from  '../../ProfilePicture/ProfilePicture'


function ChatMiddleWindow(props){
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([])
  const socket = useContext(SocketContext);
  const [chatMessages, setChatMessages] = useState([])
  const messageRef = useRef(null)
  const messagesEndRef = useRef(null)
  const [attachmentFilesList, setAttachmentFilesList] = useState([])


  function onMessage(message){
      message = JSON.parse(message)
      if(message.type === 'welcome'){
   
      }else if(message.type === 'connect_to_user'){
         console.log(message)
      }
  }

    useEffect(()=>{
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  },[props.middleBoxChatMessages])


// Function to handle selecting a customer
const selectCustomer = (customerId) => {
  setSelectedCustomerId(customerId);
};

// Function to send a new message (by agent)
const handleSendMessage = () => {
  console.log('handleSendMessage '+messageRef.current.innerHTML)
  if (messageRef.current.innerHTML.trim() === '') return;
  let session = JSON.parse(sessionStorage.getItem('ss_sess'))
  const newMessage = { type:'chat_message', content: messageRef.current.innerHTML, from: 'agent', conversationId: props.currentConversationIdRef.current, userId: session.userId, cmUserId: session.userId, accountId:  session.accountId };
  console.log('handleSendMessage '+JSON.stringify(newMessage))
  if(attachmentFilesList.length !== 0){
      const formData = new FormData();
      const filesMap = {}
      let sizes = []
      attachmentFilesList.forEach(file=>{
        formData.append("files", file.name);
        formData.append("sizes", file.size);
        formData.append("types", file.type ? file.type: 'text/plain');
        filesMap[file.name] = file
      });
      formData.append('content', messageRef.current.innerHTML)
      formData.append('conversationId', props.currentConversationIdRef.current)
      formData.append("from", "agent")
      axiosClient.postRequest(constants.CHAT_URL+'chat_message', formData)
      .then(function (response) {       
          let promises = [];
          for (var i = 0; i < response.data.attachments.length; i++) {
            promises.push(
              axiosClient.putExternalRequest(response.data.attachments[i].link,filesMap[response.data.attachments[i].name],{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': filesMap[response.data.attachments[i].name].size
              }}).then(response => {}))
            }
            Promise.all(promises).then(() => {
                newMessage['insertRecord'] = false
                setAttachmentFilesList([])
                socket.send(newMessage)  
                props.setMiddleBoxChatMessages((prevMessages) => [...prevMessages, newMessage]);
                messageRef.current.innerHTML = ''         
            });    
        }).catch((error) => {
              console.log(error)
        });
        return
  }
  socket.send(newMessage)
  props.setMiddleBoxChatMessages((prevMessages) => [...prevMessages, newMessage]);
  messageRef.current.innerHTML = ''
};


function handleAttachment(event){
    event.preventDefault();
    if(Object.keys(event.target.files).length === 0){
      return
    }
    if( event.target.files[0].size > (1000 *1000 *516))
    {
        toast.error('Maximum file size is 10MB')
        return;
    }
    setAttachmentFilesList([...attachmentFilesList,event.target.files[0]])
}
function fetchType(message){
  console.log('fetchType '+ JSON.stringify(message))
  if(message.cmUserId && message.cmUserId !== 'null')
    return 'user'
  else
    return 'customer';
}

  return( 
          
        <div class = 'chat-middle-box' >  
            <div style={{ borderBottom: '1px solid #ccc', padding: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  {(props.middleBoxChatMessages.length !== 0) ?
                    <>
                      <div class="chat-header-img" style={{ marginRight: '10px' }}>
                        
                              {props.contactImage !== null? (
                                <img src={props.contactImage} alt="Profile"  class='chat-header-img'/>
                              ) : (
                                <ProfilePicture name={props.contact ? props.contact : 'U'} classProp='chat-header-img'/>
                              )
                              }                    
                      </div>
                  
                    <div style={{width: '100%'}}>
                      <span style={{ margin: 0, color: '#007bff', fontSize: '1.2em' }}>
                        {(props.contact !== 'null') ? props.contact : 'Unknown' }</span>
                            <span style={{float: 'right' }}>{(props.status !==  'Resolved') ? 
                                <button class='ss-button'>Resolve Conversation</button> 
                            : <button class='ss-button'>Open Conversation</button> 
                        }
                      </span>
                    </div>
                   </>
                  :''
                  }
              </div>
            </div>
              <div class="chat-wrapper">
                 <div className="chat-wrapper-items" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                      {props.middleBoxChatMessages.length === 0 ? (
                        <div style={{ textAlign: 'center', fontSize: '18px', color: 'black' }}>No Chats</div>
                      ) : (
                        props.middleBoxChatMessages.map((message, index) => {
                          switch (fetchType(message)) {
                            case 'user':
                              return <ChatUserMessage key={index} text={message.content} fileNames={message.fileNames}/>;
                            case 'customer':
                              return <ChatCustomerMessage key={index} text={message.content} fileNames={message.fileNames} />;
                            case 'attachment':
                              return <ChatMessageAttachment key={index} />;
                            default:
                              return null;
                          }
                        })
                     )}
                     <div ref={messagesEndRef} />
                 </div>                  
              </div>


            <div className="chatbibox-wrapper chat-footer" style={{position: 'absolute',bottom: 0,width: '100%',backgroundColor: '#f9f9f9',borderTop: '1px solid #e0e0e0',padding: '10px 15px',display: 'flex', flexDirection:'column',justifyContent:'start'}}>
              <div style={{display: 'flex',flexWrap:'wrap',alignItems:'flex-start'}}>
                  {attachmentFilesList.map((attachment,index) => 
                      <Attachment name={attachment.name} 
                          isEditing={true}
                          index = {index}
                          size={attachment.size}
                          removeAttachmentFromEdit={props.removeAttachmentFromEdit}
                          source={'create'}/>
                  )}
              </div>
              <div style={{ width:'100%', flexGrow: 1, padding: '10px 15px',borderRadius: '20px',border: '1px solid #ccc',outline: 'none',fontSize: '0.95rem',backgroundColor: '#ffffff',color: '#333',display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                  <div className="chatbox-input" style={{ width:'100%', flexGrow: 1, padding: '10px 15px',borderRadius: '20px',border: '1px solid #ccc',outline: 'none',fontSize: '0.95rem',backgroundColor: '#ffffff',color: '#333',display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                    <div contentEditable={props.status !== 'Resolved' && props.middleBoxChatMessages.length !== 0 } ref={messageRef} style={{flexGrow: 1,padding: '8px',borderRadius: '20px',border: 'none',outline: 'none',overflowY: 'auto',whiteSpace: 'pre-wrap',textAlign: 'left',minHeight: '40px'}} placeholder="Write something..."
                      onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (e.getModifierState('Shift')) {
                              // Tab + Enter is pressed, insert a new line
                              document.execCommand('insertHTML', false, '<br><br>');
                              e.preventDefault();
                            } else {
                              // Only Enter is pressed, handle sending the message
                              e.preventDefault();
                              handleSendMessage(e);
                            }
                          }
                        }}
                    />
                     
                    <ul style={{ display: 'flex', gap: '8px', listStyleType: 'none', padding: 0, margin: 0 }}>
                        <label for="file-input" style={{verticalAlign:'baseline'}}>            
                          <img class="replybox-attachment-img" src={require('../../../img/attachment.png')}/>
                        </label>
                        <input disabled={props.status === 'Resolved' || props.middleBoxChatMessages.length === 0 } id="file-input" type="file" style = {{display: "none" }} onChange={handleAttachment}/>                    
                    </ul>
                  </div>
                  <div className="chatbibox-footer" style={{ marginLeft: '10px' }}>
                    <button 
                      className="ss-button send" 
                      style={{
                        backgroundColor: '#1f77b4',
                        border: 'none',
                        padding: '8px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={handleSendMessage}
                    >
                      <img src={require('../../../img/paper-plane.png')} style={{ width: '18px', height: '18px' }} />
                    </button>
                  </div>
              </div>
            </div>

         <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss
                draggable pauseOnHover theme="colored"/>
        </div>

          
      )

}

export default ChatMiddleWindow;