import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import {Route, Link, Routes, useParams} from 'react-router-dom';
import TicketHeader from './TicketHeader'
import CommentLine from './CommentLine'
import Attachment from './Attachment'
import LoadingAnimation from '../../LoadingAnimation'
import ReplyBox from './ReplyBox'
function TicketMiddleBox(props){
	const [ticket, setTicket] = useState(null)    
	const toRef = useRef(null)
	const commentRef = useRef(null)
	const navigate = useNavigate();
	const [commentLine, setCommentLine] = useState([])
	const errorRef = useRef(null)
	const spinnerRef = useRef(null)
  const [attachmentFilesList, setAttachmentFilesList] = useState([])

useEffect(() => {   
console.log('useEffect TicketMiddleBox')     
  setTicket(props.ticket)
  if(props.ticket.comments !== undefined)
    setCommentLine(props.ticket.comments)                           
},[])//use effect  

function addComment(event, message,to){
  console.log('addComment')
  errorRef.current.innerHTML = ''
  event.target.disabled = true
  if(message === '' && attachmentFilesList.length === 0){
      event.target.disabled = false
        return
  }

    spinnerRef.current.style.display = 'inline-block'

    let config ={}         
    const formData = new FormData();
    const filesMap = {}
    let sizes = []
    attachmentFilesList.forEach(file=>{
      formData.append("files", file.name);
      formData.append("sizes", file.size);
      formData.append("types", file.type ? file.type: 'text/plain');
      filesMap[file.name] = file
    });
    let privateComment = document.getElementsByName('private-comment')
    console.log(' privateComment '+privateComment[0].checked)
    formData.append('ticketId', ticket.id)
    formData.append('content', message)
    formData.append('to',to)
    formData.append('isPrivate',(privateComment[0].checked ? 'true' : 'false'))
    
		axiosClient.postRequest(constants.COMMENT_URL, formData,config)
    .then(function (response) {       
        let attachmentList = response.data.attachments
        if(attachmentList !== null){
          let promises = [];
          for (var i = 0; i < response.data.attachments.length; i++) {
            promises.push(
              axiosClient.putExternalRequest(response.data.attachments[i].link,filesMap[response.data.attachments[i].name],{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': filesMap[response.data.attachments[i].name].size
              }}).then(response => {}))
            }
          Promise.all(promises).then(() => {
              setCommentLine([...commentLine, response.data])
              props.ticket.comments.push(response.data)
              commentRef.current.innerHTML = ''
              setAttachmentFilesList([])
              event.target.disabled = false
              spinnerRef.current.style.display = 'none'            
          });
        }else{
              setCommentLine([...commentLine, response.data])
              props.ticket.comments.push(response.data)
              commentRef.current.innerHTML = ''
              setAttachmentFilesList([])
              event.target.disabled = false
              spinnerRef.current.style.display = 'none'           
        }        
    	}).catch((error) => {
        		console.log(error)
        event.target.disabled = false
        spinnerRef.current.style.display = 'none'
    	});
}


function closeReplyBox(){
    props.replyBoxRef.current.style.display = 'none'
}

function deleteClicked(id){
    axiosClient.postRequest(constants.COMMENT_URL+'delete/'+id)
    .then(function (response) {
      window.location.reload()
      // let commentLineTemp = commentLine
      // let commentLineTemp2 = []
      //   setCommentLine([])
      //   for(var i = 0;i<commentLineTemp.length;i++){
      //     console.log('inside '+id+"  "+commentLineTemp[i].id+" "+(commentLineTemp[i].id === id))
      //     if(commentLineTemp[i].id === id){
      //       continue;
      //     }          
      //     commentLineTemp2.push(commentLineTemp[i])
      //   }
      //   setCommentLine(commentLineTemp2)
    }).catch((error) => {

  });
}

function handleAttachment(event){
    errorRef.current.innerHTML = ''
    event.preventDefault();
    if(Object.keys(event.target.files).length === 0){
      return
    }
    if( event.target.files[0].size > (1000 *1000 *516))
    {
        errorRef.current.innerHTML = 'Maximum file size is 516MB'
        return;
    }
    setAttachmentFilesList([...attachmentFilesList,event.target.files[0]])
}

function goBack(){
    navigate(constants.TICKET_URL_ABS)
}
function removeAttachmentFromEdit(id){
    let attachmentFilesListArr = [...attachmentFilesList]
    attachmentFilesListArr.splice(id, 1);
    setAttachmentFilesList(attachmentFilesListArr)
}

function cloneTicket(){
	let payload = {"id": ticket.id}
		axiosClient.postRequest(constants.TICKET_URL+'clone',payload)
		.then(function (response) {
    		console.log(response.data)
    		navigate(constants.TICKET_URL_ABS+response.data.id)
		}).catch((error) => {
    		
		});
}

function format(event,type){
  commentRef.current.focus();
  if(event.target.parentElement.style.backgroundColor === '')  
    event.target.parentElement.style.backgroundColor = '#dddddd'
  else
    event.target.parentElement.style.backgroundColor = ''
  document.execCommand(type, false, null);
  commentRef.current.focus();  
}

  return (
  		(ticket !== null)  			?	 
              <div class="ticket-middle-box">
                 <div class="ticket-wrapper">                                          
                      <TicketHeader ticket={ticket} setSummary={props.setSummary} openContactBox={props.openTicketContactDisplayBox}/>
                      {
                        commentLine.map((comment,index) => 
                          <CommentLine comment={comment} content={comment.content} deleteClicked={deleteClicked}/>
                        )
                      }
                    <ReplyBox toRef={toRef} replyBoxRef={props.replyBoxRef} ticket={ticket} closeReplyBox={closeReplyBox}
                              commentRef={commentRef} removeAttachmentFromEdit={removeAttachmentFromEdit} handleAttachment={handleAttachment}
                              format={format} addComment={addComment} commentRef={commentRef} toRef={toRef} spinnerRef={spinnerRef} errorRef={errorRef}
                              buttonText={'Comment'} attachmentFilesList={attachmentFilesList}
                              handleAttachment={handleAttachment}/>      
                 </div> {/*End of middle page */}
            </div> 
    
    : <LoadingAnimation /> 

    )
}



export default TicketMiddleBox;