import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import CompanyLine from "./CompanyLine"
import CompanyListDisplayHeader from './CompanyListDisplayHeader'
import Pagination from '../../Pagination'
import CreateCompanyForm from './CreateCompanyForm'
import {useNavigate, useParams} from "react-router-dom"

function CompaniesListDisplay(){
	const [sessionData, setSessionData] = useState(null)
	const [count, setCount] = useState(0)
	const editIdRef = useRef(-1)
	const [filter, setFilter] = useState('all')
	const [search, setSearch] = useState('')
	const [companyLine, setCompanyLine] = useState("")
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(25)	
	const [showCreateCompanyForm, setShowCreateCompanyForm] = useState(false)
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const params = useParams();
	
	useEffect(() => {
		loadCompanies(pageNo, pageSize)
		setSessionData(JSON.parse(sessionStorage.getItem('ss_sess')))
		groupButtonsRef.current.style.visibility = 'hidden'
		if(params.id !== undefined){
			editIdRef.current = params.id
			setShowCreateCompanyForm(true)
		}		
	},[])//use effect

function loadCompanies(ps,pn){
	axiosClient.getRequest(constants.COMPANY_URL+'?pageNo='+ps+'&pageSize='+pn)
	.then(function (response) {
	let companyList = null;
	companyList = response.data.map((element, index) => (									
					<CompanyLine openCompany={openCompany} company= {element} checkBoxClicked={checkBoxClicked}/>		           
	));
		if(Object.keys(companyList).length === 0){
			setCount(0)
			setCompanyLine('No Records Found...')
		}else{
			setCompanyLine(companyList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}
	}).catch(function (response) {
	    console.log(response);
	});	
}

function selectMultipleCompanies(){
	let elements = document.getElementsByClassName('company-checkbox')
	for (var i = 0; i < elements.length; i++) {
		elements[i].style.visibility = 'visible'
	}
}

function searchCompanies(event, pn){
	setSearch(event.target.value)
	setPageNo(pn)
	axiosClient.getRequest(constants.COMPANY_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pn)
	.then(function (response) {
	let companyList = null;
	companyList = response.data.map((element, index) => (					
					<CompanyLine openCompany={openCompany} company= {element} index={index} checkBoxClicked={checkBoxClicked}/>
	));
		if(Object.keys(companyList).length === 0){
			setCount(0)
			setCompanyLine('No Records Found...')
		}else{
			setCompanyLine(companyList)
			let countTemp = response.data[0].count;
			setCount(countTemp)
		}
	}).catch(function (response) {
	    console.log(response);
	});	
}
function filterCompanies(type, pn){
	setPageNo(pn)
	axiosClient.getRequest(constants.COMPANY_URL+"filter?keyword="+type+'&pageNo='+pn)
		.then(function (response) {
		let companyList = null;
		companyList = response.data.map((element, index) => (					
						<CompanyLine openCompany={openCompany} company= {element} checkBoxClicked={checkBoxClicked}/>					           
		));
			if(Object.keys(companyList).length === 0){
				setCount(0)
				setCompanyLine('No Records Found...')
			}else{
				setCompanyLine(companyList)
				let countTemp = response.data[0].count;
				setCount(countTemp)
				setFilter(type)
			}
		}).catch(function (response) {
		    console.log(response);
		});	
}

function filterCompaniesWithSearch(search, pn){
	setPageNo(pn)
	axiosClient.getRequest(constants.COMPANY_URL+'filter_with_search?keyword='+filter+'&pageNo='+pn+'&search='+search)
	.then(function (response) {
		let companyList = null;
		companyList = response.data.map((element, index) => (					
						<CompanyLine openCompany={openCompany} company= {element} checkBoxClicked={checkBoxClicked}/>
		));
			if(Object.keys(companyList).length === 0){
				setCount(0)
				setCompanyLine('No Records Found...')
			}else{		
				let countTemp = response.data[0].count;
				setCount(countTemp)		
				setCompanyLine(companyList)
			}	
		}).catch(function (response) {
		    console.log(response)
		})	
}

function primaryCheckBoxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('company-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}
function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value)
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.children[0].style.visibility = 'visible'
	}else{
		groupButtonsRef.current.children[0].style.visibility = 'hidden'
	}
	if(!value){
		console.log('primaryCheckBoxRef '+primaryCheckBoxRef.current)
		primaryCheckBoxRef.current.checked = false
	}
}
function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.COMPANY_URL+'delete';
	else if(type === 'block')
		url = constants.COMPANY_URL+'block';
	else if(type === 'spam')
		url = constants.COMPANY_URL+'spam';

   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadCompanies(pageNo, pageSize);
		let elements = document.getElementsByClassName('company-line-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}           
       }).catch((error) => {
           console.log(error)
       });      
    groupButtonsRef.current.style.visibility = 'hidden'
    primaryCheckBoxRef.current.checked=false
}

function handlePagination(number){
	console.log('handlePagination '+ number)
	setPageNo(number)
	if(filter ==='all'){
		if(search === '')
			loadCompanies(number, pageSize)
		else{
			let event={'target':{'value': search }}
			searchCompanies(event, number)
		}
	}
	else if(filter !== 'all'){
		if(search === '')
			filterCompanies(filter, number)
		else
			filterCompaniesWithSearch(search, number)
	}		
}

function closeCreateWindow(load){
	setShowCreateCompanyForm(false)
	editIdRef.current = -1
	if(load)
		loadCompanies(0,25)
}
function showCreateCompanyFormFun(){
	setShowCreateCompanyForm(true)
}

function openCompany(id){
	console.log('openCompany '+id)
	editIdRef.current = id
	setShowCreateCompanyForm(true)	
}
	return (
		<>
		   <CompanyListDisplayHeader showCreateCompanyFormFun={showCreateCompanyFormFun}
		   							 filterCompanies={filterCompanies}
		   							 groupButtonsRef={groupButtonsRef}
		   							 performAction={performAction}
		   							 searchCompanies={searchCompanies}/>
		
			<div class="main-body">
			   <div class="page-body" >
					   <div class="container" style={{minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
         <div class="row">
            <div class="col-lg-12">
               <div class>
                  <div class="table-responsive">
                     <table class="table table-nowrap align-middle table-borderless">
                        <thead>
                           <tr>
                              <th scope="col" class="ps-4" style={{width: "50px", backgroundColor:'#f4f4f4'}}>
                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input"  ref={primaryCheckBoxRef} style={{border: '1px solid #A9A9A9'}} onClick={primaryCheckBoxClicked}/></div>
                              </th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Name</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Email</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>Phone Number</th>
                              <th scope="col" style={{backgroundColor:'#f4f4f4'}}>WorkPhone</th>
                              <th scope="col" style={{width: "200px",backgroundColor:'#f4f4f4'}}>Website</th>
                           </tr>
                        </thead>
                        <tbody>
                        	{companyLine}                    
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>         
      </div>

					   <Pagination count={count} pageNo={pageNo}
				   					 handlePagination={handlePagination}/>	   	
					</div>

					{ showCreateCompanyForm ? <CreateCompanyForm closeCreateWindow={closeCreateWindow} editIdRef={editIdRef}/> : '' }
				</div>
		</>
 );

}
export default CompaniesListDisplay;
