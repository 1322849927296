import React from 'react';
import {useState, setState, useRef, useEffect} from 'react';
import Attachment from './Attachment'
import DropdownSearch from '../../Util/DropdownSearch'
import Bold from "../../../img/bold.svg";
import Italic from "../../../img/italic.svg";
import OL from '../../../img/list-ordered.svg'
import UL from '../../../img/list-unordered.svg'
import Image from '../../../img/image.svg'
import URL from '../../../img/link.svg'
import JLeft from '../../../img/align-left.svg'
import JRight from '../../../img/align-right.svg'
import JCenter from '../../../img/align-center.svg'
import Underline from "../../../img/underline.svg";
function ReplyBox(props){
	const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const options = ["Apple", "Banana", "Orange", "Mango", "Pineapple", "Grapes"];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length > 0) {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };


  const handleOptionSelect = (option) => {
    setSearchTerm(option);
    setShowDropdown(false);
  };
	function displayCannedResponses(event){

  }

function changeFontName(e){
    e.preventDefault()
    props.commentRef.current.focus()
    document.execCommand('fontName', true, e.target.value);
 }
 function changeFontSize(e){
    e.preventDefault()
    props.commentRef.current.focus()
    document.execCommand('fontSize', false, e.target.value);
 }

  function changeForeColor(event, command, color) {
    event.preventDefault()
    props.commentRef.current.focus()
    document.execCommand('foreColor', color);
  }
  function changeHiliteColor(event, command, color) {
    console.log('changeHiltoColor '+color+' '+command)
     event.preventDefault()
    props.commentRef.current.focus()
    document.execCommand('hiliteColor', color);
  }

  function applyFormat(event,value){
    event.preventDefault()
    props.commentRef.current.focus()
    document.execCommand(value);
  }

function handleOrderList(event, type){
    event.preventDefault()
    props.commentRef.current.focus()
    document.execCommand(type);
}
  return (
                
                  <div class="reply-box-container" ref={props.replyBoxRef}>
                      <div class="reply-box-header">
                        <div class="reply-box-header-row">
                          <div class="from-chatbibox">
                            {props.toRef ? <span class="ticket-comment-to">To: <input value={props.ticket['reporter_contact'].email} ref={props.toRef} style={{width:'300px',fontSize:'16px'}}/></span> : '' }
                            <div style={{float:'right', fontSize:'16px', fontWeight:'800',cursor: 'pointer', color:'black'}} onClick ={props.closeReplyBox}>&times;</div>
                          </div>
                        </div>
                      </div>

    <div class='create-ticket-toolbar'>           
              <select id="header" style={{width:'80px', height:'25px', border: '1px solid #ccc'}}>
                  <option value="">Header</option>
                  <option value="H1">H1</option>
                  <option value="H2">H2</option>
                  <option value="H3">H3</option>
              </select>
                  <select id="fontName" onChange={changeFontSize} style={{width:'50px', height:'25px', border: '1px solid #ccc'}}>
                      <option value="2">12px</option>
                      <option value="4">14px</option>
                      <option value="6">16px</option>
                      <option value="7">18px</option>
                      <option value="8">20px</option>
                  </select>
          
              <select id="fontName" onChange={changeFontName} style={{width:'120px', height:'25px', border: '1px solid #ccc'}}>
                  <option value="">Font</option>
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Times New Roman">Times New Roman</option>
                </select>
 
                {/*<input type="color" id="textColor" onChange={(e)=> changeForeColor(e,'foreColor', e.target.value)} style={{height:'20px', width:'20px'}}/>
                  <input type="color" id="bgColor" onChange={(e)=> changeHiliteColor(e,'HiliteColor', e.target.value)} style={{height:'20px', width:'20px'}}/>
                */}
                <button onClick={(event)=>applyFormat(event, 'bold')}><img src={Bold} alt='B'/></button>
                <button onClick={(event)=>applyFormat(event, 'italic')}><img src={Italic} alt='I'/></button>
                <button onClick={(event)=>applyFormat(event, 'underline')}><img src={Underline} alt='U'/></button>
                <button onClick={(event)=>handleOrderList(event, 'insertOrderedList')}><img src={OL}/></button>
                <button onClick={(event)=>handleOrderList(event, 'insertUnorderedList')}><img src={UL}/></button>
                <button><img src={URL} /></button>

                <button onClick={(event)=>handleOrderList(event, 'justifyLeft')}><img src={JLeft} /></button>
                <button onClick={(event)=>handleOrderList(event, 'justifyCenter')}><img src={JCenter} /></button>
                <button onClick={(event)=>handleOrderList(event, 'justifyRight')}><img src={JRight} /></button>                
                <label for="file-input" style={{verticalAlign:'baseline'}}>            
                 <img class="replybox-attachment-img" src={require('../../../img/attachment.png')}/>
                </label>
                <input id="file-input" type="file" style = {{display: "none" }} onChange={props.handleAttachment}/>
            </div>  
                      <div contenteditable='true' class="reply-box-message-input" placeholder="Type a message" ref={props.commentRef}  ></div>
                      <div style={{display: 'flex',flexWrap:'wrap'}}>
                          {props.attachmentFilesList.map((attachment,index) => 
                              <Attachment name={attachment.name} 
                                  isEditing={true}
                                  index = {index}
                                  size={attachment.size}
                                  removeAttachmentFromEdit={props.removeAttachmentFromEdit}
                                  source={'create'}/>
                          )}
                      </div>
                      <div class="reply-box-options">
                          
                          <span style={{verticalAlign: 'middle', display:' flex'}}><input type='checkbox' style={{float:'right', marginRight:'5px'}} name='private-comment' ref={props.privateCommentRef}/> Private Comment </span>
                          
                          <div class="icons">
                              <button class=" ss-button" onClick={(event) => props.addComment(event, props.commentRef.current.innerHTML, props.toRef ? props.toRef.current.value : '' )}>Comment</button>
                              <span class="spinner-border" role="status" style={{color: 'white', height:'15px', width:'15px',position: 'relative',display: 'none' }} ref={props.spinnerRef}/>
                          </div>
                      </div>
                      <div ref={props.errorRef} style={{color: 'red'}}/>
                  </div>
                
    )
}

export default ReplyBox;