  import React from "react"
  import {useState, setState, useRef, useEffect} from 'react';
  import ChatMessageAttachment from './ChatMessageAttachment'

  function ChatUserMessage(props){
    const renderChatAttachments = () => {
        const fileNames = props.fileNames.split(";");
        return (
          <div>
            {fileNames.map((fileName, index) => (
              <ChatMessageAttachment key={index} fileName={fileName.split(",")[0]} />
            ))}
          </div>
        );

    }

      return(
             <div class="chat-header-row">
               <div class="chat-header-img">
                  <img src={require('../../../img/Headshot.jpg')} alt=''/>
               </div>
               <div class="chat-header-info">
                 <div class="main-header">
                   <p>{props.text}</p>
                   {renderChatAttachments()}
                 </div>
               </div>               
             </div>        
          )

  }

  export default ChatUserMessage;