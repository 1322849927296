import React,{useRef, useEffect, useState} from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";
import '../css/Quill.css'
import { Quill } from "react-quill";

 

function Quill2(props) {
  const [editorHtml, setEditorHtml] = useState("");
  // const [selectedImage, setSelectedImage] = useState(null)
  const quillRef = useRef(null)
   let selectedCell = useRef(null)
   const editorRef = useRef(null)
   let currentTable;
   let currentImage;let currentResizer = null;
   let startX, startY, startWidth, startHeight;
   var quill ;
   let deletedTable = null;

   useEffect(()=>{
      quill = new Quill('#editor', {});
      const quillEditor = quillRef.current.getEditor().root;
      if(quillEditor){
          quillEditor.addEventListener("click", handleImageClick);       
          let keyboard = quillRef.current.getEditor().getModule('keyboard');
          delete keyboard['bindings']['13']  
          delete keyboard.bindings['8']; 
          if (quill) {
            const editor = quillRef.current.getEditor();
            editor.on('text-change', () => {
              const range = editor.getSelection();
              if (range && range.composing !== undefined) {
                const currentFormat = editor.getFormat(range.index);
                if (currentFormat.size) {
                  editor.format('size', currentFormat.size);
                }
                if (currentFormat.font) {
                  editor.format('font', currentFormat.font);
                }
              }
            });

            // Convert <div> to <p> and apply font-size and font-family consistently
            editor.on('text-change', () => {
              const editorElem = document.querySelector('.ql-editor');
              editorElem.querySelectorAll('div').forEach((div) => {
                const p = document.createElement('p');
                p.innerHTML = div.innerHTML;
                const currentFormat = editor.getFormat();
                if (currentFormat.size) {
                  p.style.fontSize = currentFormat.size;
                }
                if (currentFormat.font) {
                  p.style.fontFamily = currentFormat.font;
                }

                div.replaceWith(p);
              });
            });
          }      
      }
      // if(quill){
      //     quillRef.current.getEditor().on('text-change', () => {
      //       const range = quillRef.current.getEditor().getSelection();
      //       if (range && range.composing !== undefined ) {
      //         const currentFormat = quillRef.current.getEditor().getFormat(range.index);
      //         if (currentFormat.size) {
      //           quillRef.current.getEditor().format('size', currentFormat.size);
      //         }
      //       }
      //     });

      //     quillRef.current.getEditor().on('text-change', () => {
      //         const editor = document.querySelector('.ql-editor');
      //         editor.querySelectorAll('div').forEach((div) => {
      //           const p = document.createElement('p');
      //           p.innerHTML = div.innerHTML;
      //           div.replaceWith(p);
      //         });
      //     });
      // }
   },[] )



// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}


const handleChange = value => {
  props.setState({ value });
};

function hideTableMenu() {
  let contextMenu = document.getElementById('contextMenu')
  if(contextMenu)
    contextMenu.style.display = 'none';
  document.removeEventListener('click', hideTableMenu);
}
function initResize(event) {
    let current;
    if(currentImage)
      current = currentImage;
    else if(currentTable)
      current = currentTable
    currentResizer = event.target;
    startX = event.clientX;
    startY = event.clientY;
    startWidth = parseInt(window.getComputedStyle(current).width, 10);
    startHeight = parseInt(window.getComputedStyle(current).height, 10);

    window.addEventListener('mousemove', resizeImage);
    window.addEventListener('mouseup', stopResize);
  }

function resizeImage(event) {
    let currentElement;
    if(currentImage)
      currentElement = currentImage;
    else if(currentTable)
      currentElement = currentTable
    if (!currentElement) return;

    const dx = event.clientX - startX;
    const dy = event.clientY - startY;

    if (currentResizer.classList.contains('bottom-right')) {
      currentElement.style.width = `${startWidth + dx}px`;
      currentElement.style.height = `${startHeight + dy}px`;
    } else if (currentResizer.classList.contains('bottom-left')) {
      currentElement.style.width = `${startWidth - dx}px`;
      currentElement.style.height = `${startHeight + dy}px`;
    } else if (currentResizer.classList.contains('top-right')) {
      currentElement.style.width = `${startWidth + dx}px`;
      currentElement.style.height = `${startHeight - dy}px`;
    } else if (currentResizer.classList.contains('top-left')) {
      currentElement.style.width = `${startWidth - dx}px`;
      currentElement.style.height = `${startHeight - dy}px`;
    }

    updateResizerPosition();
  }

function updateResizerPosition() {
    let currentElement;
    if(currentImage)
      currentElement = currentImage;
    else if(currentTable)
      currentElement = currentTable
    if (!currentElement) return;
    const resizers = currentElement.parentElement.querySelectorAll('.resizer');
    resizers.forEach(resizer => {
      const position = resizer.classList[1];
      if (position === 'top-left') {
        resizer.style.top = `${currentElement.offsetTop - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft - 4}px`;
      } else if (position === 'top-right') {
        resizer.style.top = `${currentElement.offsetTop - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft + currentElement.offsetWidth - 4}px`;
      } else if (position === 'bottom-left') {
        resizer.style.top = `${currentElement.offsetTop + currentElement.offsetHeight - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft - 4}px`;
      } else if (position === 'bottom-right') {
        resizer.style.top = `${currentElement.offsetTop + currentElement.offsetHeight - 4}px`;
        resizer.style.left = `${currentElement.offsetLeft + currentElement.offsetWidth - 4}px`;
      }
    });
  }

  function elementContainsSelection(el) {
    var sel = window.getSelection();
    if (sel.rangeCount > 0) {
        for (var i = 0; i < sel.rangeCount; ++i) {
            if (!el.contains(sel.getRangeAt(i).commonAncestorContainer)) {
                return false;
            }
        }
        return true;
    }
    return false;
}
  function stopResize() {
    window.removeEventListener('mousemove', resizeImage);
    window.removeEventListener('mouseup', stopResize);
  }


  function addResizers(div,type) {    
    const resizers = document.querySelectorAll('.resizer');  
    if(resizers.length > 0){    
      resizers.forEach(resizer => resizer.remove());
      currentTable = null;
      hideTableMenu()
      return
    }
    
    if(type === 'table')
      currentTable = div.children[0];
    else{
      currentImage = div.children[0]
    }
    const resizerPositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];    
    resizerPositions.forEach(position => {
      const resizer = document.createElement('div');
      resizer.classList.add('resizer', position);
      resizer.addEventListener('mousedown', initResize);
      div.appendChild(resizer);
    });
  }

  function displayTable(){
    insertTable();
  }
  function showTableMenu(event) {    
    event.preventDefault()
    let react = editorRef.current.getBoundingClientRect()
    let contextMenu = document.getElementById('contextMenu')
    contextMenu.style.display = 'block';
    contextMenu.style.left = `${event.clientX - react.left}px`;
    contextMenu.style.top = `${event.clientY - react.top}px`;
    selectedCell.current = event.target    
  }


function insertTable() {
  const editor = document.getElementsByClassName('ql-editor')[0];
  const table = document.createElement('table');
  let div = document.createElement('div')
  let br = document.createElement('br')
  div.style.position='relative'
  div.style.display='inline-block'
  table.classList.add('table-striped')
  
  for (let i = 0; i < 2; i++) {
    const row = table.insertRow();
    for (let j = 0; j < 5; j++) {
      const cell = row.insertCell();
      cell.style.height='30px'      
      cell.tabIndex = 0; // Make each cell focusable
      cell.contentEditable = true; // Allow text input inside the cell
      cell.addEventListener('click', (event) => { 
        console.log('cell clicked')  
          if (event.target.tagName === 'TD') {
              selectedCell.current = event.target;          
          }          
      }); 
    }

  }
  table.addEventListener('contextmenu', (event) => {
      if (event.target.tagName === 'TD') {
          selectedCell.current = event.target;
          showTableMenu(event);
      }    
  });

  // div.addEventListener('click', () => addResizers(div,'table'));
  table.style.width='99%'
  div.style.width='100%'
  div.appendChild(table)


  if(!elementContainsSelection(editor)){  
     editor.appendChild(div);
     editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
     editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
    return
  }
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(div);
  } else {
    editor.appendChild(div);
    editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
    editor.appendChild(br);editor.appendChild(br);editor.appendChild(br)
  }
}

function deleteTable(event){
  if (!selectedCell.current) return;
  let table = selectedCell.current.parentElement.parentElement  
  table.parentNode.removeChild(table)
  hideTableMenu()
}

function addRow(position) {
    if (!selectedCell.current) return;
    let table = selectedCell.current.parentElement.parentElement  
    let numberOfColumns = table.children[0].children.length
    let newRow = table.insertRow(0) 
    newRow.style.border='1px solid black'   
    for(var i=0;i<numberOfColumns; i++){
      let cell = newRow.insertCell(i)
      cell.style.height='30px'
      cell.style.border='1px solid black'
    }

    if(position === 'top')
      table.insertBefore(newRow,selectedCell.current.closest('tr'));    
    else{
      let tr = selectedCell.current.closest('tr').nextSibling
      if(tr)
        table.insertBefore(newRow,tr);
      else
        table.appendChild(newRow)
    }
    hideTableMenu()

  }

  function insertColumnLeft() {
    if (!selectedCell) {
        alert('Please select a cell first!');
        return;
    }

    const table = document.getElementById('myTable');
    const selectedColumnIndex = selectedCell.cellIndex; // Get the index of the selected cell's column

    // Loop through each row of the table and insert a new cell at the selected column index
    for (let row of table.rows) {
        const newCell = row.insertCell(selectedColumnIndex); // Insert a new cell at the column index
        newCell.innerHTML = 'New Cell'; // You can set this to whatever default content you want
    }
    hideTableMenu()
}

function deleteRow() {
    if (!selectedCell.current) return;
    let row = selectedCell.current.closest('tr')
    row.remove()
    hideTableMenu()
  }

  // Delete selected column
  function deleteColumn() {
    if (!selectedCell.current) return;
    const selectedColumnIndex = selectedCell.current.cellIndex;
    let table = selectedCell.current.parentElement.parentElement              
    for (let row of table.rows) {
      for(let cell of row.cells){
        if(cell.cellIndex === selectedColumnIndex)
          cell.remove()
      }
    }
    hideTableMenu()
  }
  // Add column to the table
  function addColumn(position) {
    if (!selectedCell.current) return;
    const selectedColumnIndex = selectedCell.current.cellIndex;
    let table = selectedCell.current.parentElement.parentElement      
    for (let row of table.rows) {
        if(position ===  'right')
          row.insertCell(selectedColumnIndex+1);
        else
          row.insertCell(selectedColumnIndex);
    }

  }
  function applyCellColor(value){
    if(selectedCell.current)
      selectedCell.current.style.backgroundColor=value
    // hideTableMenu()
  }


  const handleImageClick = (event) => {
    console.log('handleImageClick '+event.target.tagName)
    if (event.target.tagName === "IMG") {
      let image = event.target.parentElement;
      if(event.target.parentElement.tagName === 'P'){
        let ele = document.createElement('div')
        let breakEle = document.createElement('br')
        ele.style.position='relative'
        ele.style.display='inline-block'
        var clonedImage = event.target.cloneNode(true);
        ele.appendChild(clonedImage)
        for(var i=0;i<5;i++)
          ele.appendChild(breakEle)
        let parentElement = event.target.parentElement
        parentElement.replaceChild(ele, event.target)
        image = ele
      }
      // setSelectedImage(event.target);
      currentImage = image
      addResizers(image, 'image');
    }
    if(event.target.tagName !== "TD"){
      selectedCell.current = null;
    }
  };


  return (
   <div style={{ position:'absolute', left:'20%', width:'80%', height:'100%'}} ref={editorRef}>
      <div style={{width:'90%'}}>
            <input type='text' placeholder='title' style={{width:'100%', paddingLeft:'20px'}} required ref={props.titleRef} />
      <EditorToolbar displayTable={displayTable} />
      <ReactQuill
        theme="snow"
        value={props.state.value}
        onChange={handleChange}
        placeholder={"Type your article here..."}
        modules={modules}
        formats={formats}
        ref={quillRef}       
      />
    </div>
        <div class="context-menu" id="contextMenu">
              <button onClick={deleteTable}>Delete Table</button>
              <button onClick={()=>addRow('top')}>Add Row Above</button>
              <button onClick={()=>addRow('bottom')}>Add Row Below</button>
              <button onClick={()=>addColumn('left')}>Add Column Left</button>
              <button onClick={()=>addColumn('right')}>Add Column Right</button>
              <button onClick={deleteRow}>Delete Row</button>
              <button onClick={deleteColumn}>Delete Column</button>
              <div style={{position:'relative'}}>
                  <button onClick={() => document.getElementById('colorPicker').click()}>
                    Color
                  </button>
                  <input 
                      id='colorPicker'
                      type="color"                      
                      onChange={(e) => applyCellColor(e.target.value)}
                      style={{marginLeft: '10px', verticalAlign: 'middle',visibility:'hidden', position:'absolute'}} 
                    />
              </div>              
        </div>  
  </div>
  );
};

export default Quill2;