import React, {useRef, useState} from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import TicketHeader from './TicketHeader'
import { usePermissions } from '../../PermissionsProvider';

function TicketDisplayHeader(props){
  const { isActionAllowed, isMenuVisible } = usePermissions();
  const [edit, setEdit] = useState(false)
  const  editRef = useRef(null)
  const  inputRef = useRef(null)
  const [summary, setSummary] = useState(props.ticket.summary)
  const [buttonName, setButtonName] = useState('Comment')

function editSummary(event){
  setEdit(!edit)
  editRef.current.style.display='none'
  
}
function fetchReporter(){
  if(props.ticket['reporter_contact'].deleted === 'true')
    return props.ticket.reporter_contact.name
  return <a href={"/contacts/"+props.ticket['reporter_contact'].id}>{props.ticket.reporter_contact.name}</a>
}

function updateSummary(){
    let payload={id: props.ticket.id, summary: inputRef.current.value}
    axiosClient.postRequest(constants.TICKET_URL+'update_summary',payload)
      .then(function (response) {
        setSummary(inputRef.current.value)
        setEdit(false)
      }).catch(function (response) {
          setEdit(false)
          console.log(response);
      });
}
function changeSummary(event){
  console.log('changeSummary '+event.target.value)
  setSummary(event.target.value)
}
function cancelEdit(){
  setEdit(false)
}
function displayEdit(){
  if(!isActionAllowed('CHANGE_SUMMARY'))  return ;
  if(edit)
    return
  editRef.current.style.display='inline'
}

function hideEdit(){
  editRef.current.style.display='none'
}

function displayMiddlebox(event, type){
  let elements = document.getElementsByClassName('display-type')
  for(var i=0;i<elements.length; i++){
    elements[i].classList.remove('active')
  }
  event.target.classList.add('active')
  props.displayMiddlebox(event, type)
  if(type === 'conversations'){
    setButtonName('Comment')
  }else if(type === 'resolutions'){
    setButtonName('Resolution')
  }else if(type === 'history'){
    setButtonName('history')
  }
}

function displayTicketLeftBox(){
  props.displayTicketLeftBox()
}

  return (
<>  
  <div class="top_bar-wraper" style={{lineHeight: '1.5' }}>
    <div > <a href='/tickets' style={{textDecoration: 'none',fontSize:'16px',fontWeight:'bold',fontFamily:'Lato, Helvetica,sans-seriff'}}>All Tickets</a> > {props.ticket.ticket_number}</div>
      <div onMouseOver={displayEdit} onMouseLeave={hideEdit} class="vpn_issue" style={{marginTop: '10px' }}>
          <img src={require('../../../img/ticket-icon.png')} style={{display:'inline-block',height:'25px', width:'25px', borderRadius:'50%'}}/>
          {!edit ? <h4 style={{color: '#4c4f56',color: '#1f98bc', fontWeight:'500'}}>{summary}</h4>                    
                 :<div style={{display:'inline'}}>                   
                    <input typ='text' class='form-control' style={{paddingLeft:'10px', display:'inline', width:'90%' }} class='titillium-web-regular' ref={inputRef} value={summary} onChange={changeSummary}/>
                    <button class='ss-button' style={{marginTop:'10px',marginLeft:'26px' }} onClick={updateSummary}>Update Summary </button>
                    <button class='ss-button' style={{marginTop:'10px',marginLeft:'26px' }} onClick={cancelEdit}>Cancel </button>
                  </div>
          }
          <img style={{marginLeft: '5px', height:'15px', width:'15px',cursor:'pointer'}} src={require('../../../img/edit.png')}  class='summary_edit' onClick={editSummary} ref={editRef}/>
      </div>
      <div class="tab_links" >                      
            <ul>
                  <li onClick={(event) => displayMiddlebox(event,'conversations')}><a class="active display-type" href="#" >Conversations</a></li>
                 { isActionAllowed('VIEW_TICKET_HISTORY') && ( <li onClick={(event) => displayMiddlebox(event, 'history')}><a class="display-type" href="#">History</a></li>)}
                 { isActionAllowed('VIEW_TICKET_RESOLUTION') && (  <li onClick={(event) => displayMiddlebox(event, 'resolutions')}><a class="display-type" href="#">Resolution</a></li> )}
            </ul>
            <div class='ticket-display-header-buttons-margin'>
             { buttonName === 'history' ? '' :
               <div>
               { isActionAllowed('MERGE_TICKETS') && (<button onClick={props.mergeTickets} class='ss-button' style={{marginTop:'5px',marginBottom: '5px', marginLeft:'auto' }}>Merge Tickets</button>)}
                <button onClick={props.openReplyBox} class='ss-button' style={{marginTop:'5px',marginBottom: '5px', marginLeft:'auto' }}>{buttonName}</button>
                <img src={require('../../../img/hamburger.png')} style={{height:'18px', width:'18x'}} class='ticket-details-button' onClick={displayTicketLeftBox}/>
               </div>
             }

            </div>
      </div>      
  </div>


</>
    )
}

export default TicketDisplayHeader;