import React from "react"
import {useState, setState,useEffect, useRef} from 'react';
import { Link } from 'react-router-dom'
import * as axiosClient from '../js/Axios';
import * as constants from '../js/constants'
import "../css/sidenav.css"
import { ToastContainer, toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import CallWindow from './CallWindow/js/CallWindow'
import { usePermissions } from './PermissionsProvider';

function SideNav(){
  const { isActionAllowed, isMenuVisible } = usePermissions();
  const [sessionData, setSessionDate] = useState(null)
  const adminRef = useRef(null)
  const callWindowRef = useRef(null)
  useEffect(()=>{
    if(sessionStorage.getItem('ss_sess') === null)
        return window.location.reload()
    let ssData = JSON.parse(sessionStorage.getItem('ss_sess'))
    setSessionDate(ssData)
    if(ssData.trial && ssData.trialExpired){           
        if(!window.location.href.includes('/admin/subscriptions')){
            window.location.href='/admin/subscriptions'            
        }
    }
    if(ssData.subscriptionExpired){
        if(!window.location.href.includes('/admin/subscriptions')){
            window.location.href='/admin/subscriptions'            
        }        
    }
},[])

function navigateDashboard(){
    window.location.href='/dashboard'
}
function navigateTickets(){
    window.location.href='/tickets'
}
function navigateCalls(){
    window.location.href='/calls'
}
function navigateChats(){
  window.location.href='/chats'
}
function openCallWindow(){

}
  	return (
        <>
      { sessionData === null ? '' :  
          <div className="sidenav sidenav-elements">        
          {((sessionData.isAdmin === true) && false) ?
           <div class ='sidenav-drop-down-wrapper' onClick={navigateDashboard}>
                <div>
                    <img src={require('../img/dashboard.png')} style={{height:'15px', width:'15px',cursor:'pointer',display:'inline' }} />
                </div>
                <div  style={{display:'inline',color:'white', marginLeft:'1px',marginTop:'0px' }}><a href = '/dashboard' style={{padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Dashboard</a></div>
            </div>
            :''
         }      
             
      { (isMenuVisible('Contact')) &&
        (<Dropdown drop='end'  class='sidenav-drop-down-wrapper' style={{marginTop:'30px'}}>
                <div style={{display:'flex', flexDirection: 'column'}}>                
                    <Dropdown.Toggle id="dropdown-basic" style={{border: 'none', backgroundColor: 'rgb(30, 47, 82)'}}>
                        <div><img src={require('../img/contact.png')} aria-labelledby="dropdownMenuButton" class='sidenav-image' style={{height:'15px', width:'15px',cursor:'pointer',display:'inline' }}/></div>
                        <div  style={{color:'white', marginTop:'0px' }}><a style={{marginLeft:'5px',fontWeight:'bolder', padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Contacts</a></div>
                    </Dropdown.Toggle>                
                    <Dropdown.Menu>
                       {isActionAllowed('VIEW_CONTACT') && ( <a href = '/contacts' class='contact-submenu sidenav-drop-down-item'>Contacts</a> )}
                       {isActionAllowed('VIEW_COMPANY') && ( <a href = '/companies' class='contact-submenu sidenav-drop-down-item'>Companies</a> )}
                            {
                             (sessionData.isAdmin === true) ?
                                <> 
                                    <a href = '/contact_groups' class='contact-submenu sidenav-drop-down-item'>ContactGroups</a>
                                    <a href = '/company_groups' class='contact-submenu sidenav-drop-down-item'>CompanyGroups</a>
                                </>                               
                                :''
                            }
                    </Dropdown.Menu>                    
                </div>                
        </Dropdown>)
     }
 
    { (isMenuVisible('Ticket')) && 
        (<div class ='sidenav-drop-down-wrapper' onClick={navigateTickets}>
                <div>
                    <img src={require('../img/tickets.png')} style={{height:'20px', width:'20px',cursor:'pointer',display:'inline' }} />
                </div>
                <div  style={{display:'inline',color:'white', marginLeft:'1px',marginTop:'0px' }}><a href = '#' style={{padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Tickets</a></div>
            </div>
    )}
            <div class ='sidenav-drop-down-wrapper' onClick={navigateCalls}>
                <div>
                    <img src={require('../img/call-submenu.png')} style={{height:'15px', width:'15px',cursor:'pointer',display:'inline' }} />
                </div>
                <div  style={{display:'inline',color:'white', marginLeft:'1px',marginTop:'0px' }}><a href = '#' style={{padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Calls</a></div>
            </div>

            <div class ='sidenav-drop-down-wrapper' onClick={navigateChats}>
                <div>
                    <img src={require('../img/call-submenu.png')} style={{height:'15px', width:'15px',cursor:'pointer',display:'inline' }} />
                </div>
                <div  style={{display:'inline',color:'white', marginLeft:'1px',marginTop:'0px' }}><a href = '#' style={{padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Chats</a></div>
            </div>            

    { (isMenuVisible('Agent')) &&
            (<Dropdown drop='end'  class='sidenav-drop-down-wrapper'>
                <div>                
                    <Dropdown.Toggle id="dropdown-basic" style={{border: 'none', backgroundColor: 'rgb(30, 47, 82)'}}>
                        <div><img src={require('../img/sample.png')} aria-labelledby="dropdownMenuButton" class='sidenav-image'/></div>
                        <div style={{display:'inline',color:'white', marginLeft:'1px',marginTop:'0px' }}><a style={{fontWeight:'bolder',marginLeft:'5px', padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Agents</a></div>
                    </Dropdown.Toggle>                
                    <Dropdown.Menu>
                        <a href = '/agents' class='contact-submenu sidenav-drop-down-item' href = '/agents' style={{color:'black', textDecoration:'none', display:'block'}}>Agents</a>
                      {(sessionData.isAdmin === true) ?  <a href = '/teams' class='contact-submenu sidenav-drop-down-item' href = '/teams' style={{color:'black', textDecoration:'none', display:'block'}}>Teams</a> : '' }
                    </Dropdown.Menu>
                </div>                
            </Dropdown>            
    )}
        {
          (isMenuVisible('Admin')) ?
            <Dropdown drop='end'  class='sidenav-drop-down-wrapper'>
                <div style={{display:'flex', flexDirection: 'column'}}>                
                    <Dropdown.Toggle id="dropdown-basic" style={{border: 'none', backgroundColor: 'rgb(30, 47, 82)'}}>
                        <div><img src={require('../img/admin.png')} aria-labelledby="dropdownMenuButton" class='sidenav-image'/></div>
                        <div  style={{display:'inline',color:'white', marginLeft:'1px',marginTop:'0px' }}><a style={{fontWeight:'bolder',marginLeft:'5px', padding:'2px',textDecoration: 'none', color:'white',fontSize:'12px'}}>Admin</a></div>                        
                    </Dropdown.Toggle>                
                    <Dropdown.Menu ref={adminRef} style={{height:'100vh'}}>
                      { (isActionAllowed('VIEW_PHONE_NUMBER')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/phone_numbers' style={{color:'black', textDecoration:'none', display:'block'}}>Phone Numbers</a>)}
                      { (isActionAllowed('VIEW_CALL_ROUTE')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/call_routes' style={{color:'black', textDecoration:'none', display:'block'}}>Call Routes</a>)}
                      { (isActionAllowed('VIEW_IVR')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/ivr' style={{color:'black', textDecoration:'none', display:'block'}}>IVR</a>)}
                      { (isActionAllowed('VIEW_SLA')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/sla' style={{color:'black', textDecoration:'none', display:'block'}}>SLA</a>)}
                      { (isActionAllowed('VIEW_BUSINESS_HOURS')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/business_hours' style={{color:'black', textDecoration:'none', display:'block'}}>Business Hours</a> )}                       
                      { (isActionAllowed('VIEW_SUPPORT_EMAIL')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/support_emails' style={{color:'black', textDecoration:'none', display:'block'}}>Support Emails</a> )}                       
                      { (isActionAllowed('VIEW_KB')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/kb' style={{color:'black', textDecoration:'none', display:'block'}}>KB</a>                        )}
                      { (isActionAllowed('VIEW_CUSTOM_ROLE')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/custom_roles' style={{color:'black', textDecoration:'none', display:'block'}}>Custom Roles</a>      )}                  
                      { (isActionAllowed('VIEW_CUSTOM_FIELD')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/custom_fields' style={{color:'black', textDecoration:'none', display:'block'}}>Custom Fields</a>  )}                      
                      { (isActionAllowed('VIEW_SUBSCRIPTION_DETAILS')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/subscriptions' style={{color:'black', textDecoration:'none', display:'block'}}>Subscriptions</a>)}
                      { (isActionAllowed('VIEW_CHAT_BOT')) &&  (<a class='contact-submenu sidenav-drop-down-item' href = '/admin/chatbots' style={{color:'black', textDecoration:'none', display:'block'}}>Chat Bots</a>)}
                    </Dropdown.Menu>
                </div>
                
            </Dropdown>
          : ''          
        }       
      </div>
    }       
    </>
    );
}

export default SideNav;