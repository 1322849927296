import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'
import { usePermissions } from '../../PermissionsProvider';

function TicketListDisplayHeader(props){

const navigate = useNavigate();
const [filterState, setFilterState] = useState('All Unresolved Tickets') 
const { isActionAllowed, isMenuVisible } = usePermissions();

function selectFilter(filter, type){
	hideTicketMenu()
	setFilterState(type)
	props.preDefinedFilterTickets(filter,0)
}

function openTicket(id){
    navigate(constants.TICKET_URL_ABS +props.ticket.id);
}

function createNewTicket(){
	props.showCreateTicketFormFun()
}
function displayTicketMenu(){
  if(document.getElementById("tickets-header-menu").style.display === 'none')
    document.getElementById("tickets-header-menu").style.display = 'inline'
  else
    document.getElementById("tickets-header-menu").style.display = 'none'
}
  function hideTicketMenu(){
      document.getElementById("tickets-header-menu").style.display = 'none'
  }
return (
	<div class='header-body'>
	    <div class="ticket-header1">
				<a class="btn-icon" style={{cursor:'pointer', marginRight:'10px'}} onClick={props.displayFilterBoxFun}>
					<img src={require('../../../img/filter.png')} alt='' style={{rotate: "90deg", height: "20px",rotate:'270deg' }}/>
				</a>

		    <div class="tickets-header-dropdown" onClick={displayTicketMenu} onMouseLeave = {hideTicketMenu}>
				<a class="btn-icon">
					<img src={require('../../../img/dots.png')} alt='' style={{rotate: "90deg", height: "20px", cursor:'pointer' }}/>
				</a>				
				<div>
						<div id="tickets-header-menu" style={{display: "none"}} onClick={displayTicketMenu}>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('unresolved', 'All Unresolved Tickets') }>All Unresolved Tickets</a>	
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('all', 'All Tickets') }>All Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('unassigned', 'All Open Unassigned Tickets') }>Unassigned Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('escalated', 'All Open Escalated Tickets') }>Escalated Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('critical', 'All Open Critical Tickets') }>Critical Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('high', 'All Open High Tickets') }>High Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('medium', 'All Open Medium Tickets') }>Medium Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('low', 'All Open Low Tickets') }>Low Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('open', 'Open Tickets') }>Open Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('inprogress', 'InProgress Tickets') }>InProgress Tickets</a>						   
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('needinfo', 'Needs Information Tickets') }>Needs Information Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('closed', 'Closed Tickets') }>Closed Tickets</a>
						   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('spam', 'SPAM Tickets') }>SPAM Tickets</a>
               			   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('day', 'Created Last One day') }>Created Last One day</a>
               			   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('week', 'Created Last One week') }>Created Last One week</a>
               			   <a class="dropdown-item-menu" href="#" onClick={() => selectFilter('month', 'Created Last One Month') }>Created Last One Month</a>
            			</div>
				</div>
		    </div>	           
	    	<div class="ticket-header-left" style={{cursor: 'pointer',fontWeight:'bold' }} onClick={displayTicketMenu}>{filterState}</div>
	    	
	    	{
	    		isActionAllowed('CREATE_TICKET') &&
	         	(<button class='ss-button header-right'  onClick={createNewTicket}>+ New Ticket</button>)
	     	}
	    </div>
	</div>
    );

}

export default TicketListDisplayHeader;